import { HallowAnalyticsEvent } from "@packages/analytics";
import { URL_ACCESS, URL_SELF } from "@packages/sdk";
import { BibleIcon, CommunityIcon, DashboardIcon, HomeIcon, SearchIcon } from "@packages/ui";
export const LINKS = {
    account: {
        message: "settings_title",
        props: {
            href: "/settings"
        }
    },
    bible: {
        icon: BibleIcon,
        message: "bible_title",
        props: {
            href: "/bible"
        }
    },
    categories: {
        message: "search_category_categories",
        props: {
            href: "/meditate"
        }
    },
    community: {
        icon: CommunityIcon,
        message: "tab_title_community",
        props: {
            href: "/community"
        }
    },
    dailyReadings: {
        message: "daily_readings_title",
        props: {
            href: "/daily-readings"
        }
    },
    giftCards: {
        message: "web_menu_gift_cards",
        props: {
            href: "/gift"
        }
    },
    home: {
        icon: HomeIcon,
        message: "navigation_menu_title_home",
        props: {
            href: "/"
        }
    },
    login: {
        message: "login_title",
        props: {
            href: "".concat(URL_ACCESS, "?loginRedirect=").concat(URL_SELF),
            target: "_top"
        }
    },
    me: {
        message: "navigation_menu_title_profile",
        props: {
            href: "/library"
        }
    },
    resources: {
        message: "web_menu_resources",
        props: {
            href: "/resources"
        }
    },
    schools: {
        message: "web_menu_schools",
        props: {
            href: "/schools"
        }
    },
    search: {
        icon: SearchIcon,
        message: "navigation_menu_title_search",
        props: {
            href: "/search"
        }
    },
    tryHallowForFree: {
        message: "try_hallow_for_free",
        props: {
            href: "".concat(URL_ACCESS, "/onboarding?page=subscribe&loginRedirect=").concat(URL_SELF),
            target: "_top"
        }
    },
    parishAdminDashboard: {
        icon: DashboardIcon,
        message: "web_dashboard",
        props: {
            href: "/admin"
        },
        analyticsEvent: {
            event: HallowAnalyticsEvent.TappedWebAppNav,
            properties: {
                tab: "Admin Dashboard"
            }
        }
    }
};
