/* __next_internal_client_entry_do_not_use__ useRedirect auto */ import { useSession } from "@packages/sdk";
import { useRouter, useSearchParams } from "next/navigation";
import { useEffect } from "react";
export const useRedirect = (param)=>{
    let { redirectPath, sessionStatus } = param;
    const router = useRouter();
    const searchParams = useSearchParams();
    const { status } = useSession();
    useEffect(()=>{
        if (status === sessionStatus) {
            const searchParamsQueryString = new URLSearchParams(searchParams.toString()).toString();
            return router.push("".concat(redirectPath).concat(searchParamsQueryString.length > 0 ? "?".concat(searchParamsQueryString) : ""));
        }
    }, [
        status
    ]);
};
