import { CommunityType } from "@packages/sdk";
import { ActivityIcon, CommunityAltIcon, FriendsIcon, GivingIcon, ReportIcon, SettingsAltIcon, UpwardTrendIcon } from "@packages/ui";
export const PAD_TABS = [
    {
        Icon: UpwardTrendIcon,
        message: "web_dashboard",
        path: "dashboard",
        sidebarState: "expanded"
    },
    {
        Icon: ActivityIcon,
        message: "intentions_activity",
        path: "activity",
        sidebarState: "expanded"
    },
    {
        Icon: GivingIcon,
        message: "giving_title",
        path: "giving",
        sidebarState: "expanded",
        //TODO: Modify this condition to include networks once network support is added to PAD
        getHidden: (community)=>community.type !== CommunityType.parish
    },
    {
        Icon: CommunityAltIcon,
        message: "community_detail_groups_screen_title",
        path: "groups",
        sidebarState: "closed",
        //TODO: Modify this condition to include networks once network support is added to PAD
        getHidden: (community)=>community.type !== CommunityType.parish
    },
    {
        Icon: FriendsIcon,
        message: "community_settings_members",
        path: "members",
        sidebarState: "closed"
    },
    {
        Icon: ReportIcon,
        message: "parish_admin_dashboard_navigation_reported",
        path: "reported",
        sidebarState: "expanded"
    },
    {
        Icon: SettingsAltIcon,
        message: "settings_title",
        path: "settings",
        sidebarState: "closed"
    }
];
export const padTabsPaths = PAD_TABS.map((param)=>{
    let { path } = param;
    return path;
});
export const PAD_SUPPORT_EMAIL = "parishes@hallow.app";
export const PAD_COUNTRY_OPTIONS = [
    {
        value: "US",
        label: "United States"
    },
    {
        value: "BR",
        label: "Brazil"
    },
    {
        value: "DE",
        label: "Germany"
    },
    {
        value: "MX",
        label: "Mexico"
    },
    {
        value: "PH",
        label: "Philippines"
    },
    {
        value: "GB",
        label: "United Kingdom"
    }
];
