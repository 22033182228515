export const splitBibleHTML = (html)=>{
    // HTML is always - always - in 3 pieces
    //  1. the header before the topContentLink
    //  2. the body
    //  3. the copyright after the bottomContentLink
    // We're using components for deeplinks, so we need to attach those ourselves
    if (!html) return [
        undefined,
        undefined,
        undefined
    ];
    const htmlPieces1 = html.split('<div id="topContentLink"></div>');
    const htmlPieces2 = htmlPieces1[1].split('<div id="bottomContentLink"></div>');
    return [
        htmlPieces1[0],
        htmlPieces2[0],
        htmlPieces2[1]
    ];
};
