import { requestBible, requestBibleManifest } from "@packages/sdk";
import { revalidateTag } from "next/cache";
export const getBible = async (param)=>{
    let { translation } = param;
    var _translations_find;
    const bibleTranslations = await requestBible();
    let { cookies, translations } = bibleTranslations;
    var _translations_find_id;
    const tid = (_translations_find_id = (_translations_find = translations.find((t)=>t.name === translation)) === null || _translations_find === void 0 ? void 0 : _translations_find.id) !== null && _translations_find_id !== void 0 ? _translations_find_id : null;
    if (!tid) return [
        tid,
        null,
        translations,
        cookies
    ];
    let manifest;
    try {
        manifest = await requestBibleManifest({
            translationId: tid,
            cloudfrontCookies: cookies
        });
    } catch (e) {
        if (e.status === 403) {
            revalidateTag("bible");
            const refreshedBible = await requestBible();
            manifest = await requestBibleManifest({
                cloudfrontCookies: refreshedBible.cookies,
                translationId: tid
            });
            cookies = refreshedBible.cookies;
        } else {
            throw e;
        }
    }
    return [
        tid,
        manifest,
        translations,
        cookies
    ];
};
